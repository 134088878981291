import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import browserHistory from './shared/browserHistory'
import { ApolloProvider } from '@apollo/react-hooks';
import BffClient from './graphql/client'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-151404942-2');
ReactGA.pageview(window.location.pathname);


const app = 
    <Router history={browserHistory}>
        <ApolloProvider client={BffClient}>
            <App />
        </ApolloProvider>
    </Router>


ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
