import React from 'react'
import { WithAuth } from '..';
import { Route } from 'react-router-dom'
import LoginComponent from '../../components/login';
import AppNavBar from '../../components/navbar'
import { Container } from 'reactstrap'

const LoginRoute = rest => 
    <WithAuth>
        {({
            isAuthenticated,
            isAuthenticating,
            error,
            signIn,
            signUp,
            signUpAndSignIn,
            signOut
        }) => (
            <Route 
                {...rest} 
                render={props => 
                    <>
                        <AppNavBar isAuthenticated={isAuthenticated} isLogin={true} />
                        <Container>
                            <LoginComponent
                                {...props}    
                                signIn={signIn}
                                signUp={signUp}
                                signOut={signOut}
                                signUpAndSignIn={signUpAndSignIn}
                                isAuthenticated={isAuthenticated}
                                isAuthenticating={isAuthenticating}
                                error={error}
                            />
                        </Container>
                    </>
                }
            />
        )}
    </WithAuth>

export default LoginRoute