import React from 'react'
import AuthenticationService from '../AuthenticationService';
import { Route, Redirect } from 'react-router-dom'
import LoadingComponent from '../../shared/components/LoadingComponent'

class AuthenticatedRoute extends React.PureComponent {

    state = { initializing: true }

    componentDidMount = () => {
        AuthenticationService.initialize().then(() => {
            this.setState({ initializing: false })
        })
    }

    render = () => {

        const {
            component: Component,
            authProps,
            ...rest
        } = this.props

        const { isAuthenticated, isAuthenticating, error } = authProps
        const { initializing } = this.state

        return (
            <Route
                {...rest}
                render={props => {
                    if (isAuthenticating || initializing) {
                        return <LoadingComponent />
                    }

                    if (error) {
                        return <span> %% error %%</span>
                    }

                    if (isAuthenticated) {
                        return <Component {...props} authProps={authProps} />
                    }

                    return (
                        <Redirect to="/login" />
                    )
                }}
            />
        )
    }
}

export default AuthenticatedRoute