import { gql } from 'apollo-boost';


export const AUTHENTICATION_QUERY = gql`
    query authentication {
        authentication @client {
            email
            isAuthenticated
            isAuthenticating
            firstName
            lastName,
            error,
            cognitoId,
        }
    }
`


export const UPDATE_AUTHENTICATION_QUERY = gql`
    mutation updateAuthentication(
        $email: String!
        $isAuthenticated: Boolean
        $isAuthenticating: Boolean
        $firstName: String
        $lastName: String
        $error: String
        $cognitoId: String
    ) {
        updateAuthentication(
            email: $email
            isAuthenticated: $isAuthenticated
            isAuthenticating: $isAuthenticating
            firstName: $firstName
            lastName: $lastName
            error: $error
            cognitoId: $cognitoId
        ) @client {
            email
            isAuthenticated
            isAuthenticating
            firstName
            lastName
            error
            cognitoId
        }
    }
`


