import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Required'),
    password: Yup.string()
      .min(6, 'Too Short!')
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), "Password"]),
    terms: Yup.boolean()
      .oneOf([true], 'Must Accept Terms and Conditions')
      .required('Required')

    });

  export default SignupSchema