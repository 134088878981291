import React, { useState } from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from 'reactstrap';
import DTHQLogo from '../../assets/images/raster-logo.png'
import AuthService from '../../authorization/AuthenticationService'
import PAGE from '../../constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import BackButtonComponent from './backbutton'

const AppNavBar = ({ isLogin = false, isAuthenticated, isJudge, isSchoolAdmin, isAdmin, setView, page, history, setHistory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const onClick = (goToPage) => () => {
    setHistory([])
    setView({
        page: goToPage
    })
  }

  const resolvePageName = () => {

    const training = <h5>Training</h5> 
    switch (page) {
      case PAGE.DASHBOARD:
        return training
      case PAGE.MODULE:
        return training
      case PAGE.ASSESSMENT:
        return training
      case PAGE.ASSESSMENT_REVIEW:
        return training
      case PAGE.LAB:
        return training
      case PAGE.LAB_REVIEW:
        return training
      case PAGE.ACCOUNT_VIEW:
        return <h5>Account</h5>  
      case PAGE.MATCHMAKING:
        return <h5>Competition</h5>  
      case PAGE.JUDGE_OPEN_REVIEWS:
        return <h5>Reviews</h5>  
      case PAGE.SCHOOL_ADMIN:
        return <h5>School Admin</h5>  
      default:
        return <img src={DTHQLogo} height={50} />
    }
  }

  return (
    <>
      <Navbar color="dark" dark expand="md" variant="dark"  className="d-none d-md-flex"  >
        <Container>

          <NavbarBrand href="/authorized" >
              <img src={DTHQLogo} height={50} />
          </NavbarBrand>
          {/* <NavbarToggler onClick={toggle} /> */}
          <Collapse isOpen={isOpen} navbar >
            <Nav className="ml-auto" navbar>

            { isAuthenticated && 
                <NavItem>
                  <NavLink onClick={onClick(PAGE.DASHBOARD)} active={page === PAGE.DASHBOARD || page === PAGE.MODULE} >Training</NavLink>
                </NavItem>}
            { isAuthenticated && 
              <NavItem>
                <NavLink onClick={onClick(PAGE.MATCHMAKING)} active={page === PAGE.MATCHMAKING} >Competition</NavLink>
              </NavItem>}

            { isAuthenticated &&
              <NavItem>
                <NavLink onClick={onClick(PAGE.ACCOUNT_VIEW)} active={page === PAGE.ACCOUNT_VIEW} >Account</NavLink>
              </NavItem>}


            { isAuthenticated && isJudge &&
              <NavItem>
                <NavLink onClick={onClick(PAGE.JUDGE_OPEN_REVIEWS)} active={page === PAGE.JUDGE_OPEN_REVIEWS || page === PAGE.JUDGE_IN_PROGRESS || page === PAGE.JUDGE_COMPLETED} >Judge</NavLink>
              </NavItem>}
            { isAuthenticated && isSchoolAdmin &&
              <NavItem>
                <NavLink onClick={onClick(PAGE.SCHOOL_ADMIN)} active={page === PAGE.SCHOOL_ADMIN} >Admin</NavLink>
              </NavItem>}




              { isAuthenticated && 
                <NavItem>
                  <NavLink onClick={AuthService.signOut} >Sign Out</NavLink>
                </NavItem>}

              {/* <NavItem>
                <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
              </NavItem> */}
              
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    Option 1
                  </DropdownItem>
                  <DropdownItem>
                    Option 2
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    Reset
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}


            </Nav>
          </Collapse>


        </Container>

      </Navbar>


      <Container className="d-none d-md-flex" style={{ postion: 'absolute', top: '72px' }}  >
        <BackButtonComponent history={history} setHistory={setHistory} setView={setView}  />
      </Container>




      <Container className="d-md-none bg-dark" style={{ position: 'fixed', top: '0px', zIndex: '3000', top: '-100px', paddingTop: '100px' }} >
        { isLogin 
          ? <Row className="mt-3" >
              <Col className="text-right" >
                  <h5>{ resolvePageName() }</h5>
              </Col>
            </Row>
          : <Row className="mt-3" >
              <Col xs={2} className="w-100" >
                  <BackButtonComponent history={history} setHistory={setHistory} setView={setView} />
              </Col>
              <Col xs={8} className="text-center" >
                  <h5>{ resolvePageName() }</h5>
              </Col>
              <Col xs={2} >
                {/* { isAdmin && 
                  <FontAwesomeIcon icon={faPaperPlane} className="" style={{ height: '24px', fontSize: '24px' }} />
                } */}
              </Col>
            </Row>
        } 
      </Container> 


    </>
  )
}


export default AppNavBar