import React from 'react'
import { Row, Col, Spinner } from 'reactstrap'

const LoadingComponent = ({inline = false}) => {

    return inline 
        ? (
            <Row>
                <Col className="text-center" >
                    <Spinner color="secondary" />
                </Col>
            </Row>
        ) : (
            <div style={{ display: 'flex', height: '100vh', width: '100vw' }} >
                <Spinner style={{ width: '6rem', height: '6rem' }} color="secondary" className="mx-auto my-auto" />
            </div>
        )
}

export default LoadingComponent