import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom'
import './App.css';
import { asyncComponent } from './shared/components'
import {AuthenticatedRoute, LoginRoute} from './authorization';


const AsyncAppWrapper = asyncComponent(() =>
  import('./wrapper/app')
)

// "v=spf1 include:amazonses.com ~all"

const AsyncTerms = asyncComponent(() =>
  import('./components/terms/terms')
)

const AsyncPrivacy = asyncComponent(() =>
  import('./components/terms/privacy')
)

console.log({userAgent: window.navigator.userAgent})

const App = () => {
  return (
    <div className="ios-notch">

      <Switch>

        <Route exact path="/conditions" component={AsyncTerms} />
        <Route exact path="/privacy" component={AsyncPrivacy} />

        <LoginRoute exact path="/login" />
        <LoginRoute exact path="/login/:isSignUp" />

        <AuthenticatedRoute exact path="/authorized" component={AsyncAppWrapper} />



        <Route exact path="/">
          <Redirect to="/login" />
        </Route>

        <Route >
          <Redirect to="/authorized" />
        </Route>

      </Switch>


    </div>
  );
}

export default App;
