
const PAGE = {
    LOGIN: 'LOGIN',
    LOADING: 'LOADING',
    DASHBOARD: 'DASHBOARD',
    MODULE: 'MODULE',
    ASSESSMENT: 'ASSESSMENT',
    ASSESSMENT_REVIEW: 'ASSESSMENT_REVIEW',
    LAB: 'LAB',
    LAB_REVIEW: 'LAB_REVIEW',
    ACCOUNT_VIEW: 'ACCOUNT_VIEW',
    MATCHMAKING: 'MATCHMAKING',
    SCHOOL_ADMIN: 'SCHOOL_ADMIN',
    // SCHOOL_ADMIN_REQUESTS: 'SCHOOL_ADMIN_REQUESTS',
    // SCHOOL_ADMIN_VERIFIED: 'SCHOOL_ADMIN_VERIFIED',
    JUDGE_OPEN_REVIEWS: 'JUDGE_OPEN_REVIEWS',
    JUDGE_IN_PROGRESS: 'JUDGE_IN_PROGRESS',
    JUDGE_COMPLETED: 'JUDGE_COMPLETED',

}


export default PAGE
