import apolloClient from '../graphql/client'

import cognito from './cognito'
import { UPDATE_AUTHENTICATION_QUERY } from '../graphql/query'


class AuthenticationService {
    static isInitialized = false


    initialize = async () => {

        await this.refreshClientIfNeeded()

        if (!AuthenticationService.isInitialized) {
            AuthenticationService.isInitialized = true

            this.updateClient({
                isAuthenticating: true,
                error: null
            })


            return this.getAttributes()
                .then(
                    ({
                        email,
                        firstName,
                        lastName,
                        cognitoId
                    }) => {
                        this.updateClient({
                            email,
                            firstName,
                            lastName,
                            cognitoId,
                            isAuthenticating: false    
                        })
                    }
                ).catch(error => {
                    this.signOut()
                })
        }
        return Promise.resolve(this)
    }

    getAttributes = async () => {

        const isAuthenticated = !!cognito.currentUser && cognito.isSessionValid

        let email = null
        let firstName = null
        let lastName = null
        let cognitoId = null

        if (isAuthenticated) {
            const attributes = await cognito.getAttributes()

            email = attributes
                ? attributes
                    .filter(attr => attr.getName() === 'email')
                    .map(attr => attr.getValue())[0]
                : ''

            firstName = attributes
                ? attributes
                    .filter(attr => attr.getName() === 'name')
                    .map(attr => attr.getValue())[0]
                : ''

            lastName = attributes
                ? attributes
                    .filter(attr => attr.getName() === 'family_name')
                    .map(attr => attr.getValue())[0]
                : ''

            cognitoId = attributes
                ? attributes
                    .filter(attr => attr.getName() === 'sub')
                    .map(attr => attr.getValue())[0]
                : ''
        }

        return {
            email,
            firstName,
            lastName,
            cognitoId
        }
    }

    signUp = async credentials => {

        try {
            await this.updateClient({
                isAuthenticating: true,
                error: null
            })

            await cognito.registerUser(credentials)
        } catch (error) {
            // save error

            this.updateClient({
                error: error.messge || "SignUp Error"
            })
        } finally {
            this.updateClient({
                isAuthenticating: false
            })
        }
    }



    signIn = async (credentials) => {
        try {
            await this.updateClient({
                isAuthenticating: true,
                error: null
            })
            await cognito.login(credentials)

            const attributes = await this.getAttributes()

            await this.updateClient({
                isAuthenticating: false,
                email: credentials.email,
                firstName: attributes.firstName,
                lastName: attributes.lastName,
                cognitoId: attributes.cognitoId
            })

            return ''

        } catch (error) {
            const message = 
                error.code === 'UserNotFoundException'
                    ? 'Incorrect username or password'
                    : error.message || 'SignIn Error'
            this.updateClient({
                isAuthenticating: false,
                error: message
            })
            return message
        }
    }


    signUpAndSignIn = async (credentials) => {


        try {
            await this.updateClient({
                isAuthenticating: true,
                error: null,
            })

            await cognito.registerUser(credentials)
            await cognito.login(credentials)

            await this.updateClient({
                isAuthenticating: false,
                email: credentials.email
            })
        } catch (error) {
            let errMsg = error.message || 'Authentication Error'
            this.updateClient({
                isAuthenticating: false,
                error: errMsg
            })
            throw error
        }

    }

    signOut = (clearStore = true, onlyLocalStorage = false) => {
        if (window.localStorage) {
            window.localStorage.clear()
        }

        if (!onlyLocalStorage) {
            cognito.signOut()

            this.updateClient({}).then(() => {
                if (clearStore) {
                    apolloClient.resetStore()
                }
            })
        }
        window.location.reload()
    }


    updateClient = (overrides = {}) => {
        const isAuthenticated = !!cognito.currentUser && cognito.isSessionValid

        const variables = {
            isAuthenticated,
        }

        if (!isAuthenticated) {
            overrides.email = ''
            overrides.firstName = ''
            overrides.lastName = ''
            overrides.cognitoId = ''
        }

        return apolloClient.mutate({
            mutation: UPDATE_AUTHENTICATION_QUERY,
            variables: Object.assign({}, variables, overrides)
        })
    }


    refreshClientIfNeeded = async () => {
        console.log('refreshClientIfNeeded')
        console.log({ attr: await this.getAttributes()})
        if (!cognito.isSessionValid) {
            const refreshToken = cognito.refreshToken
            let attributes = {}
            try {
                attributes = await this.getAttributes()

            } catch (err) {
                console.log('attributes error', err)
            }
            console.log({ refreshToken, attributes })
            if (!!refreshToken) {
                await cognito.refreshUserTokens(refreshToken)
            }
        }
    }

}

export default new AuthenticationService()