import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faArrowLeft } from '@fortawesome/free-solid-svg-icons'


const BackButtonComponent = ({ history, setHistory, setView }) => {

    const goBack = () => {
        const backAction = history[0]
        setHistory(history.slice(1, history.length))
        setView(backAction)
    }

    if (history && history.length > 0) {
        return (
            <FontAwesomeIcon onClick={goBack} icon={faArrowLeft} className="" style={{ height: '24px', fontSize: '24px' }}  />
        )
    } 

    

    return (<></>)
}


export default BackButtonComponent
