import React, { useState } from 'react';
import _ from 'lodash'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Field } from 'formik'
import classnames from 'classnames';
import SignupSchema from './SignupSchema'
import LoginSchema from './LoginSchema'
import history from '../../shared/browserHistory'
import LoadingComponent from '../../shared/components/LoadingComponent'

const LoginPane = ({signIn, goToDashboard}) => {

    const [loginErrorMessage, setLoginError] = useState(undefined)

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)
        const credentials = values
        signIn(credentials)
            .then(errorMessage => {
                if (!errorMessage || errorMessage.length === 0) {
                    goToDashboard()
                } else {
                    setLoginError(errorMessage)
                }
                setSubmitting(false)
            })
    }


    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <FormGroup className="mb-3">
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            <Row>
                                <Col>
                                    {errors.email && touched.email && errors.email}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className="mb-3">
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            <Row>
                                <Col>
                                    {errors.password && touched.password && errors.password}
                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row>
                        <Col>
                            {loginErrorMessage}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button color="primary" type="submit" disabled={isSubmitting} >Submit</Button>
                        </Col>
                        <Col>
                            { isSubmitting && <LoadingComponent inline={true} />}
                        </Col>
                    </Row>
                    

                </form>
            )}
        </Formik>
    )
}

const SignUpPane = ({signUpAndSignIn, goToDashboard}) => {

    const [signUpErrorMessage, setSignUpError] = useState(undefined)

    const handleSubmit = (values, { setSubmitting }) => {
        setSubmitting(true)
        const credentials = values
        signUpAndSignIn(credentials)
            .then(errorMessage => {
                goToDashboard()

                setSubmitting(false)
            })
            .catch(err => {
                setSignUpError(err.message)
                if (err.stage == 'SIGNUP' && err.userExists) {
                    console.log("signup error and user exists")
                }
                setSubmitting(false)
            })
    }


    return (
        <Formik
            initialValues={{ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', terms: false }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>

                    <Row>
                        <Col md={6}>
                            <FormGroup className="mb-3">
                                <Label for="firstName">First Name</Label>
                                <Input type="text" name="firstName" value={values.firstName} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            <Row>
                                <Col>
                                    {errors.firstName && touched.firstName && errors.firstName}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <FormGroup className="mb-3">
                                <Label for="lastName">Last Name</Label>
                                <Input type="text" name="lastName" value={values.lastName} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            <Row>
                                <Col>
                                    {errors.lastName && touched.lastName && errors.lastName}
                                </Col>
                            </Row>
                        </Col>
                    </Row>




                    <FormGroup className="mb-3">
                        <Label for="email">Email</Label>
                        <Input type="email" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
                    </FormGroup>
                    <Row>
                        <Col>
                            {errors.email && touched.email && errors.email}
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup className="mb-3">
                                <Label for="password">Password</Label>
                                <Input type="password" name="password" value={values.password} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            <Row>
                                <Col>
                                    {errors.password && touched.password && errors.password}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={6}>
                            <FormGroup className="mb-3">
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Input type="password" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} onBlur={handleBlur} />
                            </FormGroup>
                            {/* <Row>
                                <Col>
                                    {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                                </Col>
                            </Row> */}

                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center" >
                            <Input
                                type="checkbox"
                                name="terms"
                                value={values.terms} onChange={handleChange} onBlur={handleBlur} 
                                />

                                <a href="/conditions" target="_blank" style={{color: 'white'}}>Terms & Conditions</a>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            { signUpErrorMessage }
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button color="primary" type="submit" disabled={isSubmitting} >Submit</Button>
                        </Col>
                        <Col>
                            { isSubmitting && <LoadingComponent />}
                        </Col>
                    </Row>

                </form>
            )}
        </Formik>
    )

}

const LoginComponent = (props) => {
    const {isAuthenticated, signIn, signUp, signUpAndSignIn} = props

    const signUpCode = _.get(props, 'match.params.isSignUp', "0")

    const isSignUpDirect = signUpCode === "1"

    console.log({props})
    console.log({asfd: props.match.params.isSignUp})
    console.log({signUpCode})
    console.log({isSignUpDirect})

    const goToDashboard = () => {
        history.push("/authorized")
    }

    if (isAuthenticated) {
        goToDashboard()
    }

    const [activeTab, setActiveTab] = useState(isSignUpDirect ? '2' : '1');

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <Row className="mobile-margin" style={{ marginTop: '140px' }} >
            <Col sm={{size: 10, offset: 1}} lg={{size: 8, offset: 2}} xl={{size: 6, offset: 3}} >
            
                <Nav tabs >
                    <NavItem>
                        <NavLink
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                        >
                            Login
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                        >
                            Sign Up
                        </NavLink>
                    </NavItem>
                </Nav>

                <Card>
                    <TabContent activeTab={activeTab} className="p-5" >
                        <TabPane tabId="1">
                            <LoginPane signIn={signIn} goToDashboard={goToDashboard} />
                        </TabPane>
                        <TabPane tabId="2">
                            <SignUpPane signUpAndSignIn={signUpAndSignIn} goToDashboard={goToDashboard} />
                        </TabPane>
                    </TabContent>
                </Card>

            </Col>
        </Row>
    )
}

export default LoginComponent