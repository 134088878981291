import ApolloClient from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { AUTHENTICATION_QUERY } from './query'
import resolvers, { defaults } from './resolvers'
import cognito from '../authorization/cognito'
import { valueToObjectRepresentation } from 'apollo-utilities';


// const GRAPHQL_URI = 'http://localhost:3000/graphql'

const GRAPHQL_URI = 'https://xlsr5qlkci.execute-api.us-east-1.amazonaws.com/dev/graphql'

const cache = new InMemoryCache();


const makeErrorLink = () => onError(({ graphQLErrors, networkErrors}) => {
  console.log({ graphQLErrors, networkErrors})
})

const makeAuthLink = () => new ApolloLink((op, fwd) => {
  const authorization = cognito.idToken || null

  if (authorization === null) {
    return new Observable(observer => {
      observer.next({ data: {}, errors: [{ message: " %% UNAUTHENTICATED USER MESSAGE %% "}]})
      observer.complete()
    })
  }

  const headers = { authorization }

  op.setContext({
    headers,
  })

  return fwd(op)
})


const createClient = () => {

  const errorLink = makeErrorLink()
  const authLink = makeAuthLink()

  const httpLink = new HttpLink({
    uri: GRAPHQL_URI,
    headers: {  }
  })

  const client = new ApolloClient({
    link: ApolloLink.from([ errorLink, authLink, httpLink ]),
    cache,
    resolvers
  })
  
  cache.writeQuery({
    query: AUTHENTICATION_QUERY,
    data: { ...defaults }
  })
  
  client.onResetStore(() => {
    cache.writeQuery({
      query: AUTHENTICATION_QUERY,
      data: { ...defaults }  
    })
  })

  return client
}


export default createClient()

