import React from 'react'
import { Query } from 'react-apollo'
import { AUTHENTICATION_QUERY } from '../../graphql/query'
import AuthenticationService from '../AuthenticationService'

const mapProps = ({ data, error: gqlError = null}) => {
    const { authentication = {} } = data

    const {
        email,
        isAuthenticated = false,
        isAuthenticating = false,
        cognitoId,
        error,
        firstName,
        lastName,
    } = authentication

    const { signIn, signUp, signUpAndSignIn, signOut } = AuthenticationService
    return {
        email,
        isAuthenticated,
        isAuthenticating,
        cognitoId,
        error,
        firstName,
        lastName,
        signIn,
        signUp,
        signOut,
        signUpAndSignIn
    }
}

const WithAuth = ({ children, ...rest }) => (
    <Query query={AUTHENTICATION_QUERY}>
        {props => {
            const childProps = mapProps(props)

            return children({
                ...childProps,
                ...rest
            })
        }}
    </Query>
)

export default WithAuth