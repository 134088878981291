import { CognitoRefreshToken } from 'amazon-cognito-identity-js';

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const CognitoUserPool = AmazonCognitoIdentity.CognitoUserPool;
const AWS = require('aws-sdk');
// const request = require('request');
// const jwkToPem = require('jwk-to-pem');
// const jwt = require('jsonwebtoken');
// global.fetch = require('node-fetch');

// docs to finish implementation
// https://medium.com/@prasadjay/amazon-cognito-user-pools-in-nodejs-as-fast-as-possible-22d586c5c8ec


// DEV
// const poolData = {    
//     UserPoolId : "us-east-1_gnlmCbHmY", 
//     ClientId : "5u5k7jf3pn705vi0rqesprrsp5"
// }


// PROD
const poolData = {    
    UserPoolId : "us-east-1_k1luKkaS7", 
    ClientId : "5lgdfis4rhvejds7seea5164q2"
}
const pool_region = 'us-east-1';


class Cognito {

    get userPool() {
        return new AmazonCognitoIdentity.CognitoUserPool(poolData);
    }

    get currentUser() {
        return this.userPool.getCurrentUser()
    }

    get idToken() {
        const user = this.currentUser

        return (
            user &&
            user.getSession((err, session) => {
                if (err) {
                    return null
                }
                return session.idToken.jwtToken
            })
        )
    }

    get accessToken() {
        const user = this.currentUser

        return (
            user &&
            user.getSession((err, session) => {
                if (err) {
                    return null
                }
                return session.accessToken.jwtToken
            })
        )
    }

    get refreshToken() {
        const user = this.currentUser

        return (
            user &&
            user.getSession((err, session) => {
                if (err) {
                    return null
                }
                return session.refreshToken.token
            })
        )
    }

    get isSessionValid() {
        const user = this.currentUser

        return (
            user &&
            !!user.getSession((err, session) => {
                if (err) {
                    return null
                }

                return session.isValid()
            })
        )
    }

    
    registerUser = (credentials) => {    
        var attributeList = []
        attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"name",Value:credentials.firstName}))
        attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"family_name",Value:credentials.lastName}))
        attributeList.push(new AmazonCognitoIdentity.CognitoUserAttribute({Name:"email",Value: credentials.email.toLowerCase()}))
    
        return new Promise((resolve, reject) => {
            this.userPool.signUp(
                credentials.email.toLowerCase(), 
                credentials.password, 
                attributeList, 
                null, 
                (err, result) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(result)
                    }
                }
            ) 
        })

    }
    
    
    
    login = (credentials) => {
    
    
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username : credentials.email.toLowerCase(),
            Password : credentials.password,
        });
    
        var userData = {
            Username : credentials.email.toLowerCase(),
            Pool : this.userPool
        };
    
        
        var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: result => {
                    resolve()
                },
                onFailure: reject,
        
            });
    

        })
    }


    signOut = () => {
        const user = this.currentUser

        if (user !== undefined && user !== null) {
            user.signOut()
        }


        if (window.localStorage) {
            window.localStorage.clear()
        }
    }


    getAttributes = () => {
        const attrUser = this.currentUser

        return new Promise((resolve, reject) => {
            if (attrUser) {
                attrUser.getSession((err, session) => {
                    if (err) {
                        reject(err)
                        return
                    }

                    if (!session.isValid()) {
                        reject()
                        return
                    }

                    attrUser.getUserAttributes((err2, result) =>
                        err2 ? reject(err2) : resolve(result)
                    )
                })
            } else {
                resolve()
            }
        })
    }


    refreshUserTokens = (refresh) => {
        console.log('refreshUserTokens')

        return new Promise((resolve, reject) => {
            this.currentUser.refreshSession(
                new CognitoRefreshToken({
                    RefreshToken: refresh,
                }),
                (err, session) => {
                    if (err) {
                        reject(err)
                    } else {
                        const tokens = this.getTokens(session);
                        AWS.config.credentials = this.getCognitoIdentityCredentials(tokens)
                        resolve()
                    }
                })
        })
    }


    getCognitoIdentityCredentials = (tokens) => {
        console.log('getCognitoIdentityCredentials')

        const loginInfo = {}
        loginInfo[`cognito-idp.${pool_region}.amazonaws.com/${poolData.userPoolId}`] = tokens.idToken

        const params = {
            IdentityPoolId: poolData.userPoolId,
            Logins: loginInfo
        }
        return new AWS.CognitoIdentityCredentials(params)
    }

    getTokens = (session) => {
        return {
            accessToken: session.getAccessToken().getJwtToken(),
            idToken: session.getIdToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken()
        }
    }
      
}

export default new Cognito()
