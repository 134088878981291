import { AUTHENTICATION_QUERY } from './query'
import { merge } from '../shared/utils'

const __typename = 'AuthenticationInformation'

export const defaults = {
    authentication: {
        __typename,
        email: '',
        isAuthenticated: false,
        isAuthenticating: false,
        firstName: '',
        lastName: '',
        error: null,
        cognitoId: ''
    }
}

export default {
    Mutation: {
        updateAuthentication: (
            _,
            {
                email,
                isAuthenticated,
                isAuthenticating,
                firstName,
                lastName,
                error,
                cognitoId
            },
            { cache }
        ) => {
            const { authentication: oldAuthentication } = cache.readQuery({
                query: AUTHENTICATION_QUERY
            })
            const newAuthentication= {
                __typename,
                email,
                isAuthenticated,
                isAuthenticating,
                firstName,
                lastName,
                error,
                cognitoId
            }
            return merge(oldAuthentication, newAuthentication)
        }
    }
}