
const is = x => x !== undefined && x !== null
const isObject = x => typeof x === 'object' && is(x)
const isArray = Array.isArray


const merge = (dest, src) => {
    if (isArray(dest) && isArray(src)) {
        return mergeArray(dest, src)
    }

    if (isObject(dest) && isObject(src)) {
        return mergeObject(dest, src)
    }

    if (src === undefined) {
        return dest
    }

    return src
}


const mergeArray = (dest, src) => {
    const merged = dest.map((destValue, i) => merge(destValue, src[i]))

    return dest.length < src.length
        ? merged.concat(src.slice(merged.length))
        : merged
}

const mergeObject = (dest, src) => {
    return Object.keys(src).reduce((acc, key) => {
        acc[key] = merge(dest[key], src[key])

        return acc
    }, dest)
}


export {
    merge
}